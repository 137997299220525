.title-section {
    margin: 3rem auto;
    text-align: center;
}

.title-section .header {
    font-family: "Blinker" !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.title-section .header p {
    font-size: 1.3rem !important;
}

.full-width-center-content {
    display: block;
    width: 100%;
    text-align: center;
}

.textarea-box {
    margin-top: 0.5rem !important;
    background: var(--default) !important;
    border: 0 !important;
}

.fields-group {
    margin-bottom: 2.2rem;
}

.box {
    padding: 1.3rem 1.5rem;
}
.box h3 {
    font-weight: bold;
    text-transform: uppercase;
}
.box form button {
    display: block;
    width: 100%;
    padding: 1.2rem;
    background-color: #6b47ff;
    color: white;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 700;
    cursor: pointer;
    border: 0;
    transition: 0.2s ease;
    margin-top: 2rem;
}
.box form button:hover {
    background-color: #8569f3;
}

.box.light,
.box input,
.box select {
    background-color: #f3f4f8 !important;
    border: 0 !important;
}

.textarea-box {
    margin-top: 0.5rem !important;
    background: var(--default) !important;
    border: 0 !important;
}

.box.contact {
    height: 100%;
}
.box.contact img {
    height: auto;
    width: 100% !important;
}

.box.form .ui.input,
.box.form .ui.form .fields {
    width: 100%;
    margin: 0 0 0.5rem 0;
}
@media (min-width: 768px) {
    .box.form .ui.form .fields .input + .input {
        margin-left: 0.3125rem !important;
    }
}
.box.form .ui.input input {
    background-color: white !important;
    padding: 1rem 1rem;
}
.box.form p {
    margin-bottom: 2rem;
}
.box.form .ui.selection {
    border: 0 !important;
    border-radius: 0 !important;
    padding: 1.1rem 1rem;
}
.box.form .ui.selection .text + .dropdown.icon {
    height: 100%;
    display: flex !important;
    align-items: center !important;
}
.box.form .ui.selection.active.dropdown .menu {
    border: 0 !important;
}

.button-big {
    display: block !important;
    width: 100% !important;

    color: white !important;
    font-weight: 300 !important;
    font-size: 1.1rem !important;

    margin: 1rem auto !important;

    padding: 1.5rem 7rem !important;
}

@media (max-width: 768px) {
    .d-sm-none {
        display: none !important;
    }
}