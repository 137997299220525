/*
====================================
   Scrollbar Overides
====================================
*/

* {
    scrollbar-width: thin;
    /* scrollbar-color: var(--variant3) var(--light-grey); */
}

.scrollable {
    overflow-x: hidden;
    overflow-y: scroll;
}

.scrollable::-webkit-scrollbar {
    width: 0.5rem;
}

.scrollable::-webkit-scrollbar * {
    background: transparent;
}

.scrollable::-webkit-scrollbar-thumb {
    background: var(--variant3) !important;
}

.error-input>div>input {
    background-color: #f5adad !important;
    color: var(--dark) !important;
    border: 1px solid var(--danger) !important;
}

.input-centered-value input{
    text-align: center !important;
}

svg.disabled {
    opacity: 0.5;
}

.text-truncate{
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ui-alerts .content {
    margin-right: 2rem;
}

/*
====================================
   Doc Preview
====================================
*/
#header-bar{
    display: none !important;
}

/*
====================================
   Semantic UI Overides
====================================
*/
.ui.modals.dimmer {
    z-index: 1899;
}

.ui.modal > .header {
    background: var(--light);
}

.ui.loading.segment::before {
    background: transparent;
    opacity: .5;
}

.ui.card, .ui.cards > .card {
    box-shadow: 0 1px 3px 0 #d4d4d5,0 0 0 1px #d4d4d500;
}

button.button, .buttons {
    border-radius: var(--border-radius) !important;
}

.card {
    border-radius: var(--border-radius) !important;

}

.ui.basic.buttons .button {
    border-radius: 0 !important;
    background: var(--white) !important
}




.ui.segment.rounded-edges {
    border-radius: var(--border-radius) !important;
}

.priority-selection > i.dropdown.icon{
    padding-right: 0.9rem;
    padding-left: 0.3rem;
    padding-top: 0.2rem;
    font-size: 1.2rem;
}

.button-active {
    font-weight: bold !important;
}

.active-btn-background{
    background-color: #ececec !important;
}

.not-allowed-cursor {
    cursor: not-allowed;
}

.opacity-100 {
    opacity: 1;
}

.opacity-80 {
    opacity: 0.8;
}

.opacity-50 {
    opacity: 0.5;
}

.superfield > .selection.dropdown{
    margin-top: 0.2rem;
}

.superfield div.menu{
    margin-top: 0rem !important;
    border-radius: var(--border-radius) !important;
}

.ui.fullscreen.modal > .close {
    top: -2.5rem;
    right: -2.5rem;
}

.ui.fullscreen.scrolling.modal {
    left: 2.5rem !important;
  }

.ui.fullscreen.modal {
    left: 2.5rem !important;
    margin-top: 2rem;
}

.multiple>.label>span {
    top: 0 !important;
}

.invoice-autocomplete-search>.search>.results {
    width: 100%;
}

.ui.dimmer .ui.dark-loader.loader:before {
    border-color: var(--white);
}

.ui.floating.dropdown>.menu {
    border-radius: var(--border-radius) !important;
    margin-top: 0.1rem !important;
}

.ui.dimmer .ui.dark-loader.loader:after {
    border-color: var(--dark) transparent transparent;
}

.ui.menu:not(.vertical) .right.item,
.ui.menu:not(.vertical) .right.menu {
    display: flex;
    margin-left: 0 !important;
}

.ui.tabular.menu .active.item {
    border-radius: 0 !important;
}

.ui.basic.violet.button,
.ui.basic.violet.buttons .button {
    box-shadow: 0 0 0 1px var(--primary) inset !important;
    color: var(--primary) !important;
}

.ui.basic.violet.button:hover,
.ui.basic.violet.buttons .button:hover {
    background: 0 0 !important;
    box-shadow: 0 0 0 1px var(--primary) inset !important;
    color: var(--primary) !important;
}

.circular-label {
    border-radius: 50% !important;
}

.ui.selection.dropdown {
    min-width: 0 !important;
}

.ui.selection.dropdown>.search.icon {
    line-height: auto;
}

.ui.selection.dropdown>.search.icon.clear {
    line-height: auto;
}

.dropdown {
    border-radius: var(--border-radius) !important;
}

.trash-icon {
    color: var(--light) !important;
}

.trash {
    color: var(--danger) !important;
}

.close-icon {
    color: var(--light) !important;
}

.close {
    color: var(--danger) !important;
}

.pencil {
    color: var(--dark) !important;
}

.warning {
    color: var(--danger) !important;
}

.filter-close-icon {
    cursor: pointer;
    color: var(--danger);
    margin-top: 1rem !important;
}

.filter-icon {
    background: transparent;
    color: var(--dark) !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    margin: 0 !important;
}

.filter-icon:hover {
    color: var(--primary) !important;
}

.inline-input.error input {
    border-color: var(--danger) !important
}

.big-progress-bar .bar {
    height: 20px !important;
}

.primary-super-button {
    color: var(--primary) !important;
    box-shadow: inset 0 0 0 1px var(--primary) !important;
}

.history-log-icon {
    cursor: pointer;
    opacity: 0.6 !important;
}

.history-log-icon:hover {
    opacity: 1 !important;
}

.stage-panes {
    background: none !important;
    box-shadow: none !important;
    border: none !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.hoverable-group-item:hover {
    background: var(--light-grey);
}

.invisible-input-border>div>input {
    border: 1px solid transparent !important;
}

.bold-input>div>input {
    font-weight: bold !important;
}

.invisible-input-border>.ui.selection.dropdown {
    border: 1px solid transparent !important;
}

.ui.selection.active.dropdown .menu {
    /* border: 1px solid var(--primary) !important; */
    border-top: none !important;
}

.invisible-input-border>.ui.selection.dropdown:focus {
    /* border: 1px solid var(--primary) !important; */
}

.invisible-input-border>div>input:focus {
    /* border: 1px solid var(--primary) !important; */
}

/*
====================================
   Main Navigation
====================================
*/
.fullwidth {
    width: 100% !important;
}

.search-bar .input {
    width: 100%;
}

.search-bar .input input {
    border: none;
    border-bottom: 0.1rem solid var(--light);
}

.search-bar .input input:hover {
    border-bottom: 0.1rem solid var(--primary);
}

.ui.menu .ui.dropdown .menu>.item {
    width: 100%;
}

.nav-link,
.nav-link button {
    border: none;
    outline: none;
    cursor: pointer;
    color: var(--black) !important;
}

.nav-link button:hover {
    background: rgba(0, 0, 0, 0.05) !important;
}

.ui.smallMenu.menu.fixed .ui.bigMenu.menu.fixed {
    position: sticky;
}

@media only screen and (min-width: 800px) {
    .smallMenu {
        display: none;
    }
}

@media only screen and (max-width: 800px) {
    .bigMenu {
        display: none;
    }
}

/*
====================================
   Main Content Area
====================================
*/

.d-none {
    display: none;
}

.wrapper {
    width: 100%;
    display: inline-block;
}

.bigMenu {
    height: 65px !important;
}

.ui.top.fixed.menu {
    z-index: 3 !important;
}

.bigMenu > .top.fixed.menu {
    margin-bottom: 0;
    width: calc(100% - 220px);
    left: 220px;
    transition: left 0.3s !important;
}

.collapsed .bigMenu > .top.fixed.menu {
    margin-bottom: 0;
    width: calc(100% - 70px);
    left: 70px;
    
}

.smallMenu > .top.fixed.menu {
    margin-bottom: 0;
    width: calc(100% - 220px);
    left: 220px;
    transition: left 0.3s;
}

.collapsed .smallMenu > .top.fixed.menu {
    margin-bottom: 0;
    width: calc(100% - 70px);
    left: 70px;
}

/*
====================================
   Sidebar
====================================
*/
.sidebar {
    padding-top: 0;
    width: 220px;
    overflow: auto;
    font-weight: bold;
    height: calc(100% - 0px);
    float: left;
    background: var(--primary);
    /* color: var(--light); */
    position: fixed;
    transition: width 0.3s;
    top: 0;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll; 
}

.sidebar .hide-text{
    color: var(--light);
}
.sidebar .toggle-icon{
    color: var(--light);
}

.settings-sidebar .hide-text{
    color: var(--light);
}
.settings-sidebar .toggle-icon{
    color: var(--light);
}

.settings-sidebar {
    padding-top: 0;
    width: 220px;
    overflow: auto;
    font-weight: bold;
    overflow: auto;
    height: calc(100% - 0px);
    float: left;
    background: var(--primary);
    position: fixed;
    top: 0;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll; 
}

.siderbar-list {
    margin-top: 0;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}

@media only screen and (max-width: 440px) {
    .siderbar-top {
        padding-top: 0;
        color: var(--primary);
        position: fixed;
        transition: width 0.3s;
        top: 0;
        margin-top: 0;
        width: 50px;
        overflow: auto;
        font-weight: bold;
        float: left;
        height: 69px !important;
    }
    .collapsed .smallMenu > .top.fixed.menu {
        margin-bottom: 0;
        width: 100%;
        left: 0;
        transition: left 0.3s;
    }
    .smallMenu > .top.fixed.menu {
        margin-bottom: 0;
        width: calc(100% - 50px);
        left: 50px;
    }
    .collapsed .sidebar {
        display: none;
    }
    .collapsed .settings-sidebar {
        display: none;
    }
    .sidebar {
        padding-top: 0;
        width: 100%;
        z-index: 1000;
    }
    .dashboard-stats-view {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .bigTable{
        display: none !important;
    }
    .smallTable{
        align-items: center !important;
    }
    .addition-actions {
        display: none !important;
    }
    .table-actions {
        flex-wrap: initial !important;
    }
    .bulk-actions-table {
        padding-left: 0!important;
    }
    .right-side-table {
        width: 50% !important;
    }
    .custom-filter-button-group {
        display: grid !important;
    }
    .pagination-limits {
        display: none !important;
    }
    .paginator-grid {
        flex-wrap: nowrap !important;
        padding: 0 !important;
    }
    .paginator-flex {
        display: flex;
        padding: 0 !important;
    }
    .paginator-centered {
        justify-content: center !important;
        padding: 0 !important;
    }
    .prev {
        padding-left: 0 !important;
    }
    .next {
        padding-left: 0 !important;
    }
    .pagination {
        padding-left: 0 !important;
    }
    .column-in-paginator {
        padding: 0 !important;
    }
    .ui.grid>.stackable.stackable.row>.column, .ui.stackable.grid>.column.grid>.column, .ui.stackable.grid>.column.row>.column, .ui.stackable.grid>.column:not(.row), .ui.stackable.grid>.row>.column, .ui.stackable.grid>.row>.wide.column, .ui.stackable.grid>.wide.column {
        padding: 1rem !important;
    }
    .ui.grid>[class*="five column"].row>.column {
        width: 100%!important;
    }
    .button-without-margin {
        margin: 0 !important;
    }
    .display-block {
        display: block !important;
    }
    .bug-reporter {
        display: none !important;
    }
    .settings-sidebar.scrollable {
        width: 100%;
        z-index: 1000;
    }
    .settings-hide-sidebar {
        margin-top: 0;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
    .back-to-dashboard {
        display: none !important;
    }
}

@media only screen and (min-width: 441px) {
    .dashboard-stats-view {
        padding: 1rem;
    }
    .siderbar-top{
        display: none !important;
    }
    .smallTable{
        display: none !important;
    }
    .addition-actions-dropdown {
        display: none !important;
    }
    .pagination-dropdown {
        display: none !important;
    }
    .settings-divider {
        display: none !important;
    }
    .settings-hide-sidebar {
        display: none !important;
    }
    .back-to-dashboard-icon {
        display: none !important;
    }
}

.collapsed .sidebar {
    width: 70px;
}

.collapsed .sidebar-item {
    text-align: center;
}

.sidebar-item span {
    transition: display 0.3s;
}

.sidebar-category .content {
    transition: display 0.3s;
}

.collapsed .sidebar-item span {
    display: none !important;
}

.collapsed .sidebar-category .content {
    display: none;
}

.collapsed .sidebar-category .sub {
    display: block !important;
}

.sidebar-item-popup {
    background: pink;
}

.hide-text {
    transition: opacity 0.3s display 0.6s ease-out;
    opacity: 1;
    display: inline-block;
    margin-left: 0.3rem;
}

.collapsed .hide-text {
    display: none;
    opacity: 0;
}

.collapsed .sidebar-item-popup .visible {
    opacity: 1 !important;
}

.sidebar-category {
    padding-left: 0.5rem !important;
    margin-top: 1rem !important;
    margin-bottom: 5px;
}

.sidebar-category .sub {
    margin-right: 0.8rem !important;
    padding-left: 0 !important;
    text-align: center;
    font-weight: bold !important;
    display: none !important;
    color: var(--black) !important;
}

.sidebar-item {
    cursor: pointer;
    margin-bottom: 0.2rem;
    padding: 0.8rem !important;
    border-radius: var(--border-radius);
    width: 100%;
    background: default;
    color: var(--black) !important;
}

.settings-sidebar-item {
    cursor: pointer;
    margin-bottom: 0.2rem;
    padding: 0.8rem !important;
    border-radius: var(--border-radius);
    width: 100%;
    background: default;
    color: var(--black) !important;
}

.sidebar-item .avatar {
    border-radius: 0 !important;
}

.sidebar-item.is-active {
    color: var(--dark) !important;
    background-color: var(--light) !important;
}

.sidebar-item.is-active svg {
    color: var(--dark) !important;
}

.sidebar-item.is-active:hover {
    color: var(--dark) !important;
}

.sidebar-item:hover {
    color: var(--primary) !important;
}

.sidebar-item .content {
    margin-top: 0.3rem;
    margin-left: 0.5rem;
    font-weight: bold;
}

.settings-sidebar-item .content {
    margin-top: 0.3rem;
    margin-left: 0.5rem;
    font-weight: bold;
}



/*
====================================
   Invoices sidebar
====================================
*/

.fixed-sidebar.pushable:not(body) {
    transform: none !important;
}

.fixed-sidebar.pushable:not(body)>.ui.sidebar,
.fixed-sidebar.pushable:not(body)>.fixed,
.fixed-sidebar.pushable:not(body)>.pusher:after {
    position: fixed !important;
}

/*
====================================
   Main Area
====================================
*/
.collapsed .main {
    width: calc(100% - 70px);
}

.collapsed .sidebar-item-popup .visible {
    opacity: 1 !important;
}

.sidebar-category {
    padding-left: 0.5rem !important;
    margin-top: 2rem !important;
    margin-bottom: 5px;
    color: var(--light) !important;
}

.sidebar-category .sub {
    margin-right: 0.8rem !important;
    padding-left: 0 !important;
    text-align: center;
    font-weight: bold !important;
    display: none !important;
    color: var(--light) !important;
}

.sidebar-item {
    cursor: pointer;
    padding: 0.8rem !important;
    width: 100%;
    background: default;
    color: var(--light) !important;
}

.sidebar-item .avatar {
    border-radius: 0 !important;
}

.is-active {
    color: var(--white) !important;
}

.is-active:hover {
    color: var(--white) !important;
}

.sidebar-item:hover {
    background: var(--white);
    color: var(--light);
}

.sidebar-item .content {
    margin-top: 0.3rem;
    margin-left: 0.5rem;
    font-weight: bold;
}

/*
====================================
   Main Area
====================================
*/
.collapsed .main {
    width: calc(100% - 70px);
}

.main {
    padding-top: 0;
    /* padding-bottom: 2rem; */
    float: right;
    width: calc(100% - 220px);
    transition: width 0.3s;
    background: "transparent";
}

@media only screen and (max-width: 440px) {
    .main {
        width: 100%;
    }
    .collapsed .main {
        width: 100%;
    }
}

.container {
    padding-left: 1rem;
    padding-right: 1rem;
}

/*
====================================
   Breadcrumb
====================================
*/
.breadcrumb {
    padding: 1.1rem;
    box-shadow: 0 2px 4px -2px var(--dark);
    background: var(--white);
    margin-bottom: 2rem;
}

.breadcrumb>div.breadcrumb {
    padding: 0;
    padding-bottom: 2px;
    box-shadow: none;
    background: none;
}

/*
====================================
   Unit Tree Chart
====================================
*/
foreignObject {
    transform: scale(1, 1);
    transition: transform 0.25s;
}

foreignObject:hover {
    transform: scale(1.1, 1.1);
}

/* .rd3t-tree-container {
    overflow: auto !important;
}
svg:not(:root) {
    overflow: auto !important;
} */

/*
====================================
    Tables & Pagination
====================================
*/
.rsdt-paginate {
    text-align: left !important;
}

.rsdt-sortable-icon {
    font-weight: normal !important;
}

.table-search .input {
    width: 100% !important;
}

.table-search .prompt {
    width: 100% !important;
}

.ui.table {
    border-radius: var(--border-radius);
}

.ui.table thead tr:first-child > th:first-child {
    border-radius: 0;
}

.ui.table thead tr:last-child > th:last-child {
    border-radius: 0;
}

.ui.grid {
    margin-top: 0rem;
    margin-bottom: 0rem;
    margin-left: 0rem;
    margin-right: 0rem;
}

.header-block {
    margin-bottom: 1rem !important;
    background: var(--light);
}

.item-header {
    font-weight: bold;
    width: 20rem;
}

/*
====================================
   Checkbox toggle
====================================
*/

.ui.toggle.checkbox .box:before,
.ui.toggle.checkbox label:before {
    background-color: var(--variant2);
}

.ui.toggle.checkbox input:checked~.box:before,
.ui.toggle.checkbox input:checked~label:before {
    background-color: var(--primary);
}

/*
====================================
    Progress Bar
====================================
*/
.ui.progress .bar {
    height: 5px;
    background: var(--primary);
    border-radius: 0;
}

.ui.progress:last-child {
    margin: 0;
}

/*
====================================
    Step
====================================
*/
.ui.steps .disabled.step,
.ui.steps .disabled.step:after {
    background-color: var(--light-grey);
}

/*
==================================
    MultistepForm
================================
*/
.emails-numbers-list ul {
    display: block;
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0;
    margin: 0;
}

.ui.modal.multistep-loader {
    display: none;
    text-align: center !important;
    background: #fff;
    width: 35%;
}

.ui.modal.multistep-loader .loader {
    display: flex !important;
    padding: 0;
    margin: 0;
    height: 100px;
}

.ui.red.button.del_button {
    position: absolute;
    margin-top: -16.6%;
    margin-left: 72%;
}

.contact-multi-form .column-inputs {
    display: flex;
}

.contact-multi-form .column-inputs .button {
    margin-top: 1.5px;
    margin-left: 2px;
}

.contact-multi-form .column-inputs .button-add {
    background-color: var(--primary);
}

.contact-multi-form .column-inputs .button-remove {
    background-color: var(--light-grey);
}

/*
==================================
    Projects app
================================
*/
.avatar-project-row {
    display: flex;
    position: absolute;
    top: -15px;
}

.avatar-project-row.first {
    left: 1px;
}

.avatar-project-row.second {
    left: 10px;
}

.avatar-project-row.third {
    left: 20px;
}

.avatar-project-row.fourth {
    left: 25px;
}


.avatar-project-row.number {
    left: 30px;
    border: 1px solid;
    border-color: var(--border);
    border-radius: 40px;
    font-weight: bolder;
}

.graph-center {
    margin: auto;
    position: absolute;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    top: 55px;
    z-index: 1000;
}

.project-graph canvas {
    z-index: 1;
}

.project-card .show-more {
    color: var(--primary);
}

.project-card .show-more:hover {
    text-decoration: underline;
    color: var(--primary-hover);
}

.project-card {
    box-shadow: none;
    transition: box-shadow 0.3s;
}

.project-card:hover {
    cursor: pointer;
    box-shadow: 2px 2px 2px #686868c7;
}

.project-card {
    color: var(--black) !important;
}

.project-teams-button-group .button:hover {
    background-color: var(--light-grey) !important;
}

.project-label {
    padding: 0.2rem 0.5rem;
    border-radius: var(--border-radius);
    display: inline-block;
    margin: 1px;
}

.issue-name {
    font-size: 18px;
    font-weight: bold;
}

.project-open-closed-all-buttons .button:hover {
    background-color: var(--light-grey) !important;
}

.project-open-closed-all-buttons .button.active {
    background-color: var(--light-grey) !important;
}

.project-graph.statistics .graph-center {
    margin: auto;
    position: absolute;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    top: 100px;
}

/*
==================================
    Employee table
================================
*/

.ui.centered.grid,
.ui.centered.grid>.row,
.ui.grid>.centered.row {
    padding: 0;
}

.link_color {
    color: var(--dark) !important;
}

.white-color {
    color: var(--white) !important;
}

.avatar-group {
    padding: 0.5rem !important;
    display: 'flex' !important;
    align-items: 'center' !important;
    justify-content: 'left' !important;
}

.avatar-group :hover {
    background: rgb(216, 216, 216) !important;
}

.avatar-group.team-member :hover {
    background: transparent !important;
}

.icon-color {
    color: white !important;
    opacity: 100% !important;
}

/*  Table */
.table.responsive{
    min-width: 760px;
}

.table{
    height: auto;
}

/* FlexTable Design */
.flex-table.responsive{
    min-width: 960px;
}
.flex-table {
    height: auto;
}

.flex-table.stripped > .flex-row:nth-child(2n + 1) {
    background: var(--light) !important;
}

.flex-table.hoverable > .flex-row:nth-child(1n + 2):hover {
    background: var(--light-grey) !important;
}

a.primary {
    border-radius: 0 !important;
    color: var(--white) !important;
}

.filter-action {
    color: var(--dark);
    padding-left: 0rem;
    padding-right: 1rem;
    cursor: pointer;
    font-weight: bold;
}

.filter-action:hover {
    color: var(--primary);
}

.filter-action.active {
    color: var(--primary);
    text-decoration: underline;
}

.ref-link {
    cursor: pointer;
}

.ref-link:hover {
    text-decoration: underline;
    color: var(--primary);
}

.back-to {
    cursor: pointer;
}

.back-to:hover {
    transform: scale(1.2);
    color: var(--primary);
}

.publisher-box {
    display: block;
    width: 95%;
    padding: 2rem;
    background: var(--grey);
    border: 2px solid var(--light-grey) !important;
    opacity: 0.8;
    cursor: pointer;
    text-align: center;
    margin: 0.5rem;
    font-size: 2rem;
    font-weight: bold;
}

.publisher-box.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.publisher-box.disabled:hover {
    opacity: 0.5;
    border: 2px solid var(--light-grey) !important;
}

.publisher-box:hover {
    opacity: 1;
    border: 2px solid var(--primary) !important;
}

.ui.form .field .prompt.label {
    white-space: normal;
    /* color: var(--danger) !important; */
    background: transparent !important;
    border: none !important;
    margin-left: 0;
    padding-left: 0;
    margin-top: 0;
}

.ui.form .fields {
    margin-bottom: 1em;
}

/* .ui.form .field.error .input{
    color: var(--danger) !important
} */

.field>.pointing.label {
    position: unset !important;
}

.field>.ui.pointing.label::before {
    /* position: unset !important; */
    content: unset;
}

.segment-hover:hover {
    border-bottom: 1px solid var(--dark);
}

.segment-hover.active {
    background: var(--blue);
    color: var(--white);
}

/****
  ** PROJECTS
****/
.toggle-button {
    cursor: pointer;
    transition: transform 0.05s;
}

.toggle-button:hover {
    color: var(--primary);
    transform: scale(1.05);
}

.toggle-button.active {
    color: var(--primary);
}

.report-search>.input {
    width: 100%;
}

.demo-app {
    display: flex;
    min-height: 100%;
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    font-size: 14px;
}

.demo-app-sidebar {
    width: 300px;
    line-height: 1.5;
    background: #eaf9ff;
    border-right: 1px solid #d3e2e8;
}

.demo-app-sidebar-section {
    padding: 2em;
}

.demo-app-main {
    flex-grow: 1;
    padding: 3em;
}

.template-editor {
    border: 1px solid #c8c8c8;
    padding: 1rem;
    height: 500px !important;
}

.template-toolbar {
    border: 1px solid #c8c8c8 !important;
    border-radius: 0px !important;
}

.rdw-option-wrapper,
.rdw-dropdown-wrapper,
.rdw-dropdown-optionwrapper {
    border: 1px solid #c8c8c8 !important;
}

.placeholder-ul {
    visibility: hidden;
}

.placeholder-li {
    border-bottom: 1px solid #c8c8c8;
}

.placeholder-li:hover {
    background: #f1f1f1;
}

.bug-reporter {
    position: fixed;
    z-index: 1000;
    bottom: 2rem;
    right: 2rem;
    padding: 0.7rem;
    box-shadow: 5px 5px 20px #888888;
}

.bug-reporter.non-active {
    background-color: var(--primary);
    border: none;
    /* border-radius: 50%; */
    transition-property: background-color, border;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    /* transition-delay: 1s; */
    /* transition: border-radius 0.2s ease-in-out; */
}

.bug-reporter.active {
    background-color: var(--white);
    border: 1px solid var(--dark);
    /* border-radius: 0; */
    transition-property: background-color, border;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    /* transition-delay: 1s; */
    /* transition: border-radius 0.2s ease-in-out; */
}

.bug-reporter img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.bug-reporter .image-wrapper {
    height: 30%;
}

.bug-reporter-box.non-active {
    width: 0px;
    height: 0px;
    transition-property: width, height;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
}

.bug-reporter-box.active {
    width: 40vw;
    height: 64vh;
    transition-property: width, height;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
}

.bug-reporter-box.active.with-image {
    width: 40vw;
    height: 85vh;
    transition-property: width, height;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
}

.bug-reporter-form.non-active {
    visibility: hidden;
    opacity: 0;
    transition-property: visibility, opacity;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
}

.bug-reporter-form.active {
    visibility: visible;
    opacity: 1;
    transition-property: visibility, opacity;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    transition-delay: 0.45s;
}

.bug-reporter-form.active.with-image {
    visibility: visible;
    opacity: 1;
    transition-property: visibility, opacity;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    transition-delay: 0.45s;
}

.overlay {
    z-index: 100;
}

.issue-modal .content {
    padding: 0 !important;
}

.issue-modal .menu .item {
    min-width: 0 !important;
}

.attendance-table {
    overflow-x: auto;
}

.child-issue {
    padding-left: 3rem !important;
    border-left: 2px solid var(--border);
}

.ultimate-project-issues-item:hover {
    background: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.95);
    z-index: 13;
}

/* .item.ultimate-project-issues-menu-item{
    padding:0px !important
} */
.ultimate-project-issues-item {
    position: relative;
    cursor: pointer;
    display: block;
    border: none;
    height: auto;
    text-align: left;
    border-top: none;
    line-height: 1em;
    color: rgba(0, 0, 0, 0.87);
    padding: 0.78571429rem 1.14285714rem !important;
    font-size: 1rem;
    text-transform: none;
    font-weight: 400;
    box-shadow: none;
}

.estimated_time_form>.field.superfield {
    margin: 0 !important;
}

.estimated_time_form>.field.superfield>.input>input {
    padding: 0.3rem !important;
}

.project-stats-hoverable:hover {
    background: var(--variant5) !important;
    cursor: pointer;
}

.on-hover:hover {
    opacity: 0.8 !important;
}

.stages-filter::-webkit-scrollbar {
    display: none;
}

.rdw-editor-toolbar.rich-text__toolbar {
    display: none;
}

.rdw-editor-wrapper:focus-within .rich-text__toolbar {
    display: flex;
}



.pagination>li {
    display: inline-block;
    padding-left: 0;
    color: white;
    cursor: pointer;
}

.pagination>li {
    list-style: none;
    border: 0.9px solid;
}

.pagination>li>a,
.pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    color: #fff;
    background-color: #fff;
    margin-left: -1px;
}

.pagination>li.active>a {
    color: white;
    background-color: var(--primary);
    border-color: var(--primary);
}

li.page-item.active>a {
    color: white !important
}

/* Style the active class (and buttons on mouse-over) */
.pagination>li>a:hover {
    background-color: var(--primary-hover);
    color: white !important;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
    margin-left: 0;
    padding: 0px;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    display: none !important;
    color: white;
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-bottom-right-radius: 4px;
    margin-right: 0;
    padding: 0px !important;
    border-top-right-radius: 4px;
    display: none !important;
    color: white;
}

#react-doc-viewer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: hidden;
    background: #ffffff;
    min-height: 57rem !important;
}

.label-super-picker .text {
    position: relative !important;
    top: -0.3rem !important
}

.milestone-choice {
    opacity: 1 !important;
}

.milestone-choice .ui.disabled.dropdown, .milestone-choice .ui.dropdown .menu > .disabled.item {
    cursor: default;
    pointer-events: none;
    opacity: 1 !important;
}

.milestone-choice .ui.dropdown > .clear.dropdown.icon {
    opacity: .8;
    transition: opacity .1s ease;
    transition-property: opacity;
    transition-duration: 0.1s;
    transition-timing-function: ease;
    transition-delay: 0s;
    display: none;
}

.ui.secondary.pointing.menu .active.item {
    background-color: #ff151500;
    box-shadow: none;
    border-color: var(--primary);
    font-weight: 700;
    color: rgba(0,0,0,.95)
}

.item-center {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.datepicker-payment {
    margin-bottom: 0.5rem;
    font-size: 1rem;
}

.profile-preview .UserAvatar {
    z-index: 10;
}

.profile-preview .UserAvatar--inner {
    border: 5px solid var(--white)
}

.table-to-excel-action{
    background: transparent;
    border: none;
    margin-top: 2rem;
    font-size: 1.5rem;
    cursor: pointer;
}

.rounded-edges{
    border-radius: 0.3rem !important
}

.ui.fixed.top.menu {
    position: fixed;
    top: 0;
    z-index: 2;
}

.pushable > .pusher{
    z-index: 1 !important;
}

.side-panel {
    position: fixed;
    z-index: 5 !important;
    top: 0;
    right: -100%;
    bottom: 0;
    background-color: #fff;
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.3);
    overflow-y: auto;
    transition: right 0.45s ease-out;
    -ms-overflow-style: none;  /* Internet Explorer and Edge */
    scrollbar-width: none;     /* Firefox */
}

.side-panel::-webkit-scrollbar {
    display: none;
}
  
.side-panel.open {
    right: 0;
}

.side-panel-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.85);
    z-index: 5 !important;
}

.side-panel-overlay.show {
    display: block;
}

.action-hover {
    cursor: pointer;
    transition: opacity 0.5s ease-out;
    opacity: 0.5;
}

.action-hover:hover {
    opacity: 1;
}

.ui.floating.dropdown > .menu {
    margin-top: 0rem !important;
}

@media (min-width: 960px) {
    .side-panel {
        transform: translateX(0);
    }
}

@media (max-width: 960px) {
    .side-panel {
        transform: translateX(100%);
        width: 100%;
    }

    .side-panel.open {
        transform: translateX(0);
    }
}








