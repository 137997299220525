/* This file contain HRIIS THEME v1.0 */

/*
====================================
   Theme Variables
====================================
*/
:root{
    --border-radius: 3px;
    --default: #ffffff;
    --white: #ffffff;
    --black: #292929;
    --dark: #292929;
    --dark-hover: #1a1a1a;
    --light: #F2F4F6;
    --light-hover: #F2F4F6;
    --light-grey: #eeeeee;

    --primary: #6b48ff;
    --primary-hover: #552fe4;
    --info: #31C6E8;
    --info-hover: #1bafd1;
    --success: #1EE3CF;
    --success-hover: #16c8b6;
    --warning: #EEE722;
    --warning-hover: #c9c313;
    --danger: #FF4C78;
    --danger-hover: #e73863;
    --blue: #31C6E8;
    --iceblue: #1EE3CF;


    --border: #ccc;
    --radius: 0.0625rem;
    --radius-circled: 2rem;

    /* Text Colors */
    --body: --black;
    --variant1: #313131;
    --variant2: #6C757D;
    --variant3: #9F9F9F;
    --variant4: #C6C6C6;
    --variant5: #e7e7e7;
}

/* 
====================================
   Theme Fonts
====================================
*/
@font-face {
    font-family: "Daniel";
    src: url("../fonts/daniel.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Dosis";
    src: url("../fonts/Dosis-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Blinker";
    src: url("../fonts/Blinker-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

/*
====================================
    Theme Default Styles
====================================
*/
body {
    margin: 0;
    box-sizing: border-box;
    font-family: "Dosis" !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--white) !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/*
====================================
    Bouncing Loader animation
====================================
*/
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10%;
}

/* Loader circles */
.loader > span {
    background: var(--primary);
    border-radius: 50%;
    margin: 5rem 0.5rem;
    animation: bouncingLoader 0.6s infinite alternate;
}

.loader > span:nth-child(2) {
    animation-delay: 0.2s;
}

.loader > span:nth-child(3) {
    animation-delay: 0.4s;
}

/* Define the animation called bouncingLoader. */
@keyframes bouncingLoader {
    from {
        width: 0.5rem;
        height: 0.5rem;
        opacity: 1;
        transform: translate3d(0);
    }
    to {
        width: 2rem;
        height: 2rem;
        opacity: 0.1;
        transform: translate3d(0, -2rem, 0);
    }
}

/*
====================================
SemanticUI Overides Variables
====================================
*/
.ui.search .prompt {
    border-radius: 0;
    width: 500px;
}

.ui.images .image,
.ui.images > img,
.ui.images > svg {
    margin: 0 !important;
}

/* Cards */
.card.event-type-active,
.card.event-type-active:hover {
    background-color: var(--primary);
}

.card.registration-account-type {
    border: 3px solid var(--light);
}

.card.registration-account-type:hover {
    border: 3px solid var(--primary);
}

.ui.segment{
    border-radius: 0 !important;
}

input, select, textarea, .dropdown{
    border-radius: var(--border-radius) !important;
}

.ui.selection.dropdown,.ui.search.selection.dropdown{
    border-radius: var(--border-radius) !important;
}

button:not(.circular){
    border-radius: var(--border-radius) !important;
}

.modal{
    border-radius: var(--border-radius) !important;
}

.ui.primary.button, .ui.primary.buttons .button{
    background-color: var(--primary) !important;
}

a {
    color: var(--primary) !important;
    text-decoration: none;
}

.header {
    font-family: "Dosis" !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.primary-super-button{
    color: var(--primary) !important;
    box-shadow: inset 0 0 0 1px var(--primary) !important
 }

/*
====================================
    Error Page Styles
====================================
*/
.error-page {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--dark);
    height: 100vh;
    width: 100%;
    text-align: center;
    color: var(--light);
}

.error-page code {
    font-size: 10rem;
    text-align: center;
}

.error-page p {
    margin: 0;
    padding-bottom: 2rem;
    font-size: 2.5rem;
    text-align: center;
}

.error-page a {
    margin: 0;
    padding-bottom: 2rem;
    font-size: 2.5rem;
    text-align: center;
}

div.center-element {
    height: 100vh;
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    /* align-items: center; */
}


/*
====================================
    Custom Styles
====================================
*/
div.center-segment {
    height: auto;
}

.dashboard-h3 {
    line-height: 1.5rem !important;
}


/*
====================================
    Styles for Responsive Design
====================================
*/
@media (min-width: 320px) {
    table {
        display: block;
    }
}

@media (min-width: 768px) {
    table {
        display: table;
    }
}


/*
====================================
    Invoices sidebar
====================================
*/